<div class="auth-content-wrapper">
  <div class="auth-content-box reset-account-block">
    <div class="card-box">
      <div class="site-logo">
        <img src="assets/images/gfd-logo-new.svg" width="232" alt="gfd-logo" />
      </div>
      <h3 class="sec-main-title">Reset Your Password</h3>

      <div class="auth-form-group" [formGroup]="resetPassForm">
        <div class="otp-form-group">
          <div class="otp-input-control">
            <input type="text" class="form-control" maxlength="1" formControlName="digitOne" #otpInput
              (keypress)="generalService.numberOnly($event)" (input)="handleInput(1)" />
            <input type="text" class="form-control" maxlength="1" formControlName="digitTwo" #otpInput
              (keypress)="generalService.numberOnly($event)" (input)="handleInput(2)" />
            <input type="text" class="form-control" maxlength="1" formControlName="digitThree" #otpInput
              (keypress)="generalService.numberOnly($event)" (input)="handleInput(3)" />
            <input type="text" class="form-control" maxlength="1" formControlName="digitFour" #otpInput
              (keypress)="generalService.numberOnly($event)" />
          </div>

          <ng-container *ngIf="!resendClicked">
            <span class="resend-otp">Resend OTP in {{ counter }}s</span>
          </ng-container>

          <ng-container *ngIf="resendClicked">
            <button (click)="resendOTP()" class="resend-otp">Resend OTP</button>
          </ng-container>
        </div>
        <div *ngIf="
            (f['digitOne'].invalid && f['digitOne'].touched) ||
            (f['digitTwo'].invalid && f['digitTwo'].touched) ||
            (f['digitThree'].invalid && f['digitThree'].touched) ||
            (f['digitFour'].invalid && f['digitFour'].touched)
          " class="cross-validation-error-message mb-2">
          <div class="required-note text-danger">
            Please enter 4 digit OTP *
          </div>
        </div>
        <div class="form-group form-floating"
          [ngClass]="{'error': (!isPwdValid && f['password'].valid) || f['password'].invalid && (f['password'].touched) && f['password'].errors?.['required'], 'success': f['password'].valid && isPwdValid }">
          <div class="password-group">
            <input type="password" class="form-control" [type]="showPwd ? 'text' : 'password'"
              formControlName="password" (input)="validatePwd()" placeholder="New Password" (paste)="(false)" />
            <label for="floatingInput">Password</label>
            <span class="show-pwd" id="show-hide-pwd" (click)="showPwd = !showPwd"><img [src]="
                  !showPwd
                    ? 'assets/images/pwd-off.svg'
                    : 'assets/images/show-hide-icon.svg'
                " alt="pwd-icon" /></span>
          </div>
          <div *ngIf="f['password'].invalid && f['password'].touched" class="cross-validation-error-message">
            <div *ngIf="f['password'].errors && f['password'].errors['required']" class="required-note text-danger">
              Password is required *
            </div>
          </div>
        </div>
        <div class="form-group form-floating"
          [ngClass]="{'error': (!passMatch && f['retypePassword'].valid) || f['retypePassword'].invalid && (f['retypePassword'].touched) && f['retypePassword'].errors?.['required'], 'success': f['retypePassword'].valid && passMatch }">
          <div class="password-group">
            <input placeholder="Confirm New Password" [type]="showRetypePwd ? 'text' : 'password'" class="form-control"
              id="pwd" formControlName="retypePassword" (input)="comparePassword()" (paste)="(false)" />
            <label for="floatingInput">Re Enter Password</label>
            <span class="show-pwd" id="show-hide-pwd" (click)="showRetypePwd = !showRetypePwd">
              <img [src]="
                  !showRetypePwd
                    ? 'assets/images/pwd-off.svg'
                    : 'assets/images/show-hide-icon.svg'
                " alt="pwd-icon" />
            </span>
          </div>
          <div *ngIf="f['retypePassword'].invalid && f['retypePassword'].touched"
            class="cross-validation-error-message">
            <div *ngIf="
                f['retypePassword'].errors &&
                f['retypePassword'].errors['required']
              " class="required-note text-danger">
              Confirm new password is required *
            </div>
          </div>
          <div *ngIf="resetPassForm.value.retypePassword && !passMatch" class="cross-validation-error-message">
            <div *ngIf="!passMatch" class="required-note text-danger">
              Password entered does't match *
            </div>
          </div>
        </div>
        <div class="password-checklist">
          <h6>Password must contain:</h6>
          <ul>
            <li [ngClass]="{
                checked: passStrength?.passStrength?.contains.includes('number')
              }">
              Contain at least one numeric character
            </li>
            <li [ngClass]="{
                checked:
                  passStrength?.passStrength?.contains.includes('uppercase')
              }">
              Contain at least one uppercase letter
            </li>
            <li [ngClass]="{
                checked:
                  passStrength?.passStrength?.contains.includes('lowercase')
              }">
              Contain at least one lowercase letter
            </li>
            <li [ngClass]="{ checked: passStrength?.isContainSpace }">
              Not contain any spaces
            </li>
          </ul>
        </div>
        <button class="btn t-primary-btn btn-block" (click)="setNewPwd()"
          [disabled]="!passMatch || !resetPassForm.valid">
          Reset Password
        </button>
        <div class="acc-link-name mt-5">
          Go Back To
          <a [routerLink]="['/login']">Login</a>
        </div>
      </div>
    </div>
  </div>
</div>