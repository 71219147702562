<div class="custom-dialog-modal modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        Uploaded {{ invoiceType == 1 ? 'Tax' : '' }} Invoice
      </h4>
      <button type="button" #closeModal class="close" data-dismiss="modal" aria-label="Close" (click)="closeMdl()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="add-new-ticket-block">
        <div class="request-invoice-ticket ticket-chat-thread">
          <div class="head-info">
            <div class="personal-info">
              <h5 class="name">{{ invoiceTicketDetails?.customerName }}</h5>
              <div class="amount-paid">
                Total Amount Paid
                <strong>AED {{ finalTotal | number : '1.2-2' }}</strong>
              </div>
            </div>
            <div class="ordernumber">
              Order Number #{{ invoiceTicketDetails?.orderNumber }}
            </div>
          </div>
          <div class="fm-upload-block">
            <label class="upload-files-box" for="uploadFiles">
              <h6>Upload file</h6>
              <p>File format: PDF</p>
              <input #invoiceUploadInput (change)="handleFileInput($event)" accept="application/pdf" type="file"
                id="uploadFiles" />
            </label>
            <div class="upload-action mt-1">
              <a href="javascript: void(0)" *ngIf="invoieFileName">{{
                invoieFileName
                }}</a>
              <a href="javascript: void(0)" *ngIf="invoiceImageUrl" [href]="invoiceImageUrl" target="_blank"
                [download]="invoieFileName" class="ml-3">Review</a>
            </div>
          </div>
        </div>
        <div class="bottom-btnrow">
          <button type="button" class="btn t-primary-btn" (click)="sendInvoice()">
            <img src="assets/images/paper-plane-outline.svg" alt="send" /> Send
          </button>
        </div>
      </div>
    </div>
  </div>
</div>