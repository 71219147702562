import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit, Optional } from '@angular/core';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { CoreApiUrls } from '@app/core/api-constant';
import { GeneralService } from '@app/core/services';
import { GenericService } from '@app/core/services/generic.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';
import { IApiResponse } from '@app/shared/model/api-response.model';
import { PAGE_CONTENT } from '@app/shared/model/page.model';
import { AirDatepickerOptions, AirDatepickerPosition } from 'air-datepicker';
import * as moment from 'moment';
import { Select2OptionData } from 'ng-select2';
import { takeUntil } from 'rxjs';
@Component({
  selector: 'app-home-page-row-configure-modal',
  templateUrl: './home-page-row-configure-modal.component.html',
  styleUrls: ['./home-page-row-configure-modal.component.scss'],
})
export class HomePageRowConfigureModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  rowName = '';
  contentCode = PAGE_CONTENT;
  configuration: Select2OptionData[] = [];
  categories: Select2OptionData[] = [];
  configureData: Select2OptionData[] = [];
  products: Select2OptionData[] = [];
  banners: Select2OptionData[] = [];
  configureDetail: { category_id: string }[];
  selectedCategory = new UntypedFormControl('');
  selectedSubCategory = new UntypedFormControl('');
  minEndTime = '00:00';
  subCatregoryData: any[] = [];
  CategoryName: any[] = [];
  productsData: Select2OptionData[] = [];
  statusList: Select2OptionData[] = [
    { id: '1', text: 'Enabled' },
    { id: '0', text: 'Disabled' },
  ];

  startDateOptions: AirDatepickerOptions = {
    dateFormat: 'dd/MM/yyyy',
    position: 'top left',
    minDate: moment().add(1, 'day').toDate(),
  };
  endDateOptions: AirDatepickerOptions = {
    dateFormat: 'dd/MM/yyyy',
    position: 'top left',
    minDate: moment().add(1, 'day').toDate(),
  };

  homePageForm: UntypedFormGroup = new UntypedFormGroup({
    selectedCategory: new UntypedFormControl(''),
    selectedSubCategory: new UntypedFormControl(''),
    title: new UntypedFormControl('', [Validators.required]),
    price: new UntypedFormControl(''),
    startDate: new UntypedFormControl(''),
    startTime: new UntypedFormControl('00:00'),
    endDate: new UntypedFormControl(''),
    endTime: new UntypedFormControl('00:00'),
    status: new UntypedFormControl('1'),
  });

  constructor(
    @Optional()
    public dialogRef: DialogRef<any>,
    @Optional()
    @Inject(DIALOG_DATA)
    public data: {
      headerName: string;
      code: string;
      categoryList: {
        name: string;
        status: number;
        type: number;
        _id: string;
      }[];
      productList: {
        category_id: string;
        text: string;
      }[];
      productAutoCompleteList: any[];
      bannerList: any[];
      serviceList: any[];
      serviceAutoCompleteList: any[];
      configureDetail: { category_id: string }[];
      details: any;
    },
    private genericService: GenericService,
    private generalService: GeneralService
  ) {
    super();
  }

  get getTitle() {
    if (this.data.code === this.contentCode.Category) {
      return 'Category';
    }

    if (
      this.data.code === this.contentCode.ServiceCarouse ||
      this.data.code === this.contentCode.ServiceGrid
    ) {
      return 'Service';
    }

    if (this.data.code === 'CATEGORY') {
      return 'Category';
    }

    if (this.data.code === this.contentCode.Banner) {
      return 'Banner';
    }

    return 'Product';
  }

  get getSubTitle() {
    if (
      this.data.code === this.contentCode.ServiceCarouse ||
      this.data.code === this.contentCode.ServiceGrid
    ) {
      return 'Service ';
    }

    if (
      this.data.code === this.contentCode.ProductCarouse ||
      this.data.code === this.contentCode.ProductGrid ||
      this.data.code === this.contentCode.Flash
    ) {
      return 'Product ';
    }

    return '';
  }

  @Input() set headerName(value) {
    this.rowName = value;
  }

  ngOnInit(): void {
    if (this.data) {
      this.headerName = this.data.headerName;
      this.configureDetail = this.data?.configureDetail;
      this.patchConfigure();
      this.getDetails();
    }
    this.homePageForm.controls['startDate'].valueChanges.subscribe((item) => {
      const minDate = item
        ? moment(item, 'DD/MM/YYYY').toDate()
        : moment().toDate();

      if (item > this.homePageForm.value.endDate) {
        this.homePageForm.controls['endDate'].reset();
        this.setEndDateOption(minDate);
      }
    });
  }

  drop(event: any) {
    moveItemInArray(
      this.configuration,
      event.previousIndex,
      event.currentIndex
    );
  }

  onCategoryChanges() {
    if (this.homePageForm.get('selectedCategory').value) {
      const selectedCategory = this.configureData.find(
        (item) => item.id === this.homePageForm.get('selectedCategory').value
      );
      this.configuration = [selectedCategory, ...this.configuration];

      const allCategoryIds = this.configuration.map((item) => item?.id);

      this.configureData = this.configureData.filter(
        (item) => !allCategoryIds?.includes(item.id)
      );

      this.homePageForm.get('selectedCategory').setValue('');
    }
  }

  onProductCategoryChanges(event: any) {
    if (event) {
      const selectedCategory = this.subCatregoryData.find(
        (item) => item.id === event
      );
      this.configuration = [selectedCategory, ...this.configuration];

      const allCategoryIds = this.configuration.map((item) => item?.id);

      this.subCatregoryData = this.subCatregoryData.filter(
        (item) => !allCategoryIds?.includes(item.id)
      );

      this.homePageForm.get('selectedSubCategory').setValue('');
    }
  }

  getCategoryWiseSubcategorys(event?: any, apiCall?: boolean) {
    const selectedCategoryValue = event;
    if (!selectedCategoryValue) {
      this.generalService.displayError('Please Select Category');
      return;
    }
    this.subCatregoryData = [];
    const code = this.data.code;
    const categoryId = apiCall ? '' : selectedCategoryValue;
    const api =
      code === this.contentCode.ProductGrid ||
      code === this.contentCode.ProductCarouse ||
      code === this.contentCode.Flash
        ? `${CoreApiUrls.bannerUrls.productAutocomplete}?categoryId=${categoryId}`
        : `${CoreApiUrls.servicestUrls.autocomplete}?categoryId=${categoryId}`;
    this.genericService
      .getEntityDetails<IApiResponse>(api)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res: IApiResponse) => {
          if (res?.data?.length) {
            res?.data.map(
              (item) =>
                (this.subCatregoryData = [
                  ...this.subCatregoryData,
                  { id: item?._id, text: item?.name },
                ])
            );
            const allCategoryIds = this.configuration.map((item) => item?.id);
            this.subCatregoryData = this.subCatregoryData.filter(
              (item) => !allCategoryIds.includes(item.id)
            );
          } else {
            this.subCatregoryData = [];
          }
        },
        error: (error) => {
          this.generalService.displayError(error?.error?.message);
          this.homePageForm.get('selectedCategory').setValue('');
        },
      });
  }

  saveChanges() {
    this.homePageForm.markAllAsTouched();
    if (this.homePageForm.valid) {
      let startDate = null;
      let endDate = null;
      if (this.homePageForm.value.startDate) {
        const time = this.homePageForm.value.startTime;
        const date = formatDate(
          moment(this.homePageForm.value.startDate, 'DD-MM-YYYY').toDate(),
          'yyyy-MM-dd',
          'en-US'
        );

        startDate = moment
          .utc(date)
          .set({
            hour: moment(time, 'HH:mm').hour(),
            minute: moment(time, 'HH:mm').minute(),
            second: 0,
            millisecond: 0,
          })
          .toISOString();
      }

      if (this.homePageForm.value.endDate) {
        const time = this.homePageForm.value.endTime;
        const date = formatDate(
          moment(this.homePageForm.value.endDate, 'DD/MM/YYYY').toDate(),
          'yyyy-MM-dd',
          'en-US'
        );
        endDate = moment
          .utc(date)
          .set({
            hour: moment(time, 'HH:mm').hour(),
            minute: moment(time, 'HH:mm').minute(),
            second: 0,
            millisecond: 0,
          })
          .toISOString();
      }

      const data = {
        section: this.getSection(),
        sectionInfo: {
          title: this.homePageForm?.value?.title || '',
          minPrice: this.homePageForm?.value?.price || 0,
          startDate: startDate || '',
          endDate: endDate || '',
        },
        status: this.homePageForm?.value.status || 1,
        items: this.configuration.map((item) => ({
          id: item.id,
        })),
      };

      this.close(data);
    }
  }

  startTimeChange() {
    const startDate = moment(
      this.homePageForm.controls['startDate'].value,
      'DD/MM/YYYY'
    ).toDate();
    const endDate = moment(
      this.homePageForm.controls['endDate'].value,
      'DD/MM/YYYY'
    ).toDate();
    const startTime = this.homePageForm.controls['startTime'].value;
    const endTime = this.homePageForm.controls['endTime'].value;
    const isSameDate = moment(startDate, 'DD/MM/YYYY').isSame(endDate, 'day');

    if (startDate && endDate && isSameDate && startTime >= endTime) {
      this.minEndTime = moment(startTime, 'HH:mm')
        .add(1, 'hours')
        .format('HH:mm');
      this.homePageForm.controls.endTime.setValue(this.minEndTime);
    } else {
      this.minEndTime = '00:00';
    }
  }

  close(data?: any): void {
    this.dialogRef?.close(data || null);
  }

  removeCategory(idx: number) {
    if (this.configuration[idx]) {
      const deletedItem = this.configuration.splice(idx, 1);
      this.configureData = [...deletedItem, ...this.configureData];
      this.categories = [...deletedItem, ...this.categories];
      this.subCatregoryData = [...deletedItem, ...this.subCatregoryData];
    }
  }

  reset(): void {
    this.patchConfigure();
  }

  private patchConfigure(): void {
    switch (this.data.code) {
      case PAGE_CONTENT.Category:
        this.getCategories();
        this.configureData = this.categories;
        break;
      case PAGE_CONTENT.Banner:
        this.getBannerList();
        this.configureData = this.banners;
        break;
      case PAGE_CONTENT.Flash:
        this.getProducts();
        this.productsData = this.products;
        break;
      case PAGE_CONTENT.ServiceGrid:
        this.getService();
        break;
      case PAGE_CONTENT.ServiceCarouse:
        this.getService();
        break;
      default:
        this.getProducts();
        this.productsData = this.products;
    }
  }

  private getCategories() {
    this.categories = this.data?.categoryList?.map((item) => ({
      id: item._id,
      text: item.name,
    }));

    const selectedConfigIds = this.configureDetail?.map(
      (item) => item.category_id
    );
    const selectedConfigData = this.categories.filter((item) =>
      selectedConfigIds?.includes(item.id)
    );
    this.configuration = [...selectedConfigData];

    this.categories = this.categories.filter(
      (item) => !selectedConfigIds?.includes(item.id)
    );
  }

  private getService() {
    const serviceList = this.data?.serviceList?.map((item) => ({
      id: item.category_id,
      text: item.text,
    }));

    this.subCatregoryData =
      this.data?.serviceAutoCompleteList?.map((item) => ({
        id: item._id,
        text: item.name,
      })) || [];

    const selectedConfigIds = this.configureDetail?.map(
      (item) => item.category_id
    );
    const selectedConfigData = serviceList.filter((item) =>
      selectedConfigIds?.includes(item.id)
    );
    this.configuration = [...selectedConfigData];

    this.productsData = serviceList.filter(
      (item) => !selectedConfigIds?.includes(item.id)
    );
  }

  private getProducts() {
    this.products =
      this.data?.productList?.map((item) => ({
        id: item.category_id,
        text: item.text,
      })) || [];

    this.subCatregoryData =
      this.data?.productAutoCompleteList?.map((item) => ({
        id: item._id,
        text: item.name,
      })) || [];

    const selectedConfigIds = this.configureDetail?.map(
      (item) => item.category_id
    );
    const selectedConfigData = this.categories.filter((item) =>
      selectedConfigIds?.includes(item.id)
    );
    this.configuration = [...selectedConfigData];

    this.subCatregoryData = this.subCatregoryData.filter(
      (item) => !selectedConfigIds?.includes(item.id)
    );
  }

  private getBannerList() {
    this.banners =
      this.data?.bannerList?.map((item) => ({
        id: item._id,
        text: item.title,
      })) || [];

    const selectedConfigIds =
      this.configureDetail?.map((item) => item?.category_id) || [];
    const selectedConfigData =
      this.banners?.filter((item) => selectedConfigIds?.includes(item?.id)) ||
      [];
    this.configuration = [...selectedConfigData];

    this.banners = this.banners?.filter(
      (item) => !selectedConfigIds?.includes(item?.id)
    );
  }

  private getSection() {
    let data = '';

    switch (this.data.code) {
      case PAGE_CONTENT.Category:
        data = 'category';
        break;
      case PAGE_CONTENT.Banner:
        data = 'banner';
        break;
      case PAGE_CONTENT.Flash:
        data = 'flash_sale';
        break;
      case PAGE_CONTENT.ServiceCarouse:
        data = 'service_carousel';
        break;
      case PAGE_CONTENT.ServiceGrid:
        data = 'service_grid';
        break;
      case PAGE_CONTENT.ProductCarouse:
        data = 'product_carousel';
        break;
      default:
        data = 'product_grid';
    }

    return data;
  }

  private getDetails() {
    if (this.data.details) {
      let startDate = '';
      let endDate = '';
      let startTime = '00:00';
      let endTime = '00:00';
      if (this.data.details.sectionInfo?.startDate) {
        startDate = formatDate(
          moment(this.data.details.sectionInfo?.startDate).toDate(),
          'dd/MM/yyyy',
          'en-US'
        );
        startTime = moment
          .utc(this.data.details.sectionInfo?.startDate)
          .format('HH:mm');
      }
      if (this.data.details.sectionInfo?.endDate) {
        endDate = formatDate(
          moment(this.data.details.sectionInfo?.endDate).toDate(),
          'dd/MM/yyyy',
          'en-US'
        );
        endTime = moment
          .utc(this.data.details.sectionInfo?.endDate)
          .format('HH:mm');
      }

      this.homePageForm.patchValue({
        title: this.data.details.sectionInfo?.title,
        price: this.data.details.sectionInfo?.minPrice,
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime,
        status: this.data.details.status,
      });
      if (this.data.details.sectionInfo?.startDate) {
        this.endDateOptions = {
          ...this.endDateOptions,
          minDate: moment(startDate, 'DD/MM/YYYY').toDate(),
        };
        this.setStartDateOption(
          moment(this.data.details.sectionInfo?.startDate).toDate(),
          [moment(this.data.details.sectionInfo?.startDate).toDate()]
        );
      }
      if (this.data.details.sectionInfo?.endDate) {
        this.setEndDateOption(
          moment(this.data.details.sectionInfo?.startDate).toDate(),
          [moment(this.data.details.sectionInfo?.endDate).toDate()]
        );
      }
      if (startDate === endDate) {
        this.minEndTime = moment(startTime, 'HH:mm')
          .add(1, 'hours')
          .format('HH:mm');
      }
      if (this.data.details.items.length) {
        const allCategoryIds = this.data.details.items.map((item) => item?.id);

        const code = this.data.code;
        if (
          code === this.contentCode.Category ||
          code === this.contentCode.Banner
        ) {
          this.configuration = this.configureData.filter((item) =>
            allCategoryIds.includes(item.id)
          );
          this.configureData = this.configureData.filter(
            (item) => !allCategoryIds.includes(item.id)
          );
        } else {
          this.configuration = this.subCatregoryData.filter((item) =>
            allCategoryIds.includes(item.id)
          );

          this.subCatregoryData = [];
        }
      }
    }
  }

  private setEndDateOption(
    minDate: Date,
    selectedDates?: Array<string | number | Date> | false,
    dateFormat?: string,
    position?: AirDatepickerPosition
  ) {
    const options: AirDatepickerOptions = {
      ...this.endDateOptions,
      minDate: minDate,
      dateFormat: dateFormat || this.endDateOptions.dateFormat,
      position: position || this.endDateOptions.position,
    };

    if (selectedDates && selectedDates.length) {
      options['selectedDates'] = selectedDates;
    } else {
      options['selectedDates'] = false;
    }

    this.endDateOptions = {
      ...options,
    };
  }

  private setStartDateOption(
    minDate: Date,
    selectedDates?: Array<string | number | Date> | false,
    dateFormat?: string,
    position?: AirDatepickerPosition
  ) {
    const options: AirDatepickerOptions = {
      ...this.startDateOptions,
      minDate: minDate,
      dateFormat: dateFormat || this.endDateOptions.dateFormat,
      position: position || this.endDateOptions.position,
    };

    if (selectedDates && selectedDates.length) {
      options['selectedDates'] = selectedDates;
    } else {
      options['selectedDates'] = false;
    }

    this.startDateOptions = {
      ...options,
    };
  }
}
