<div class="add-new-ticket-block">
  <div class="ticket-chat-thread" *ngIf="msgList.length">
    <!-- <ng-container *ngFor="let item of msgList">
      <div
        class="thread-block"
        [ngClass]="vendorId == item?.from_admin ? 'send-msg' : 'recive-msg'"
      >
        <div class="date">{{ item.time | date : 'dd/MM/yyyy, h:mm a' }}</div>
        <h4 class="title">{{ item.title }}</h4>
        <p class="desc p-2" [innerHtml]="item.description"></p>
        <div
          class="file-attached-group mt-2"
          *ngIf="item?.ticket_files?.length > 0"
        >
          <div
            class="img-item mr-2"
            *ngFor="let ticketFile of item?.ticket_files; let i = index"
          >
            <ng-container
              *ngIf="
                item.file_name[i] | fileType : ['jpeg', 'jpg', 'png'];
                else fileType
              "
            >
              <div class="img-url">
                <a
                  type="button"
                  class="download-btn"
                  (click)="
                    generalService.downloadFile(ticketFile, item.file_name[i])
                  "
                >
                  <img
                    class="ticket-img"
                    src="{{ ticketFile }}"
                    alt="ticket-img"
                  />
                </a>
              </div>
            </ng-container>
            <ng-template #fileType>
              <div class="file-attached-card">
                <a
                  type="button"
                  class="download-btn"
                  (click)="
                    generalService.downloadFile(ticketFile, item.file_name[i])
                  "
                >
                  <div class="file-attached-item">
                    <div class="file-type-info">
                      <div class="file-icon">
                        <img
                          *ngIf="item.file_name[i] | fileType : 'csv'"
                          src="assets/images/CSV.svg"
                          alt="file-type"
                        />
                        <img
                          *ngIf="item.file_name[i] | fileType : 'pdf'"
                          src="assets/images/PDF.svg"
                          alt="file-type"
                        />
                        <img
                          *ngIf="item.file_name[i] | fileType : 'txt'"
                          src="assets/images/TXT.svg"
                          alt="file-type"
                        />
                        <img
                          *ngIf="item.file_name[i] | fileType : 'zip'"
                          src="assets/images/ZIP.svg"
                          alt="file-type"
                        />
                        <img
                          *ngIf="
                            !(
                              item.file_name[i]
                              | fileType : ['csv', 'pdf', 'txt', 'zip']
                            )
                          "
                          src="assets/images/pin-attach-icon.svg"
                          alt="file-type"
                        />
                      </div>
                    </div>
                    <div class="file-detail-info">
                      <div class="name">{{ item?.file_name[i] }}</div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container> -->
    <ng-container *ngFor="let item of msgList">
      <div class="thread-block" [ngClass]="vendorId == item?.from_admin ? 'send-msg' : 'recive-msg'">
        <div class="date">{{ item.time | date : 'dd/MM/yyyy, h:mm a' }}</div>
        <h4 class="title">{{ item.title }}</h4>
        <p class="desc p-2" [innerHtml]="item.description"></p>
        <div class="file-attached-group mt-2" *ngIf="item.files.length > 0">
          <div class="img-item mr-2" *ngFor="let file of item.files">
            <ng-container *ngIf="['jpeg', 'jpg', 'png'].includes(file.file_type); else fileType">
              <div class="img-url">
                <a type="button" class="download-btn"
                  (click)="generalService.downloadFile(file.img_url, file.file_name)">
                  <img class="ticket-img" [src]="file.img_url" alt="ticket-img" />
                </a>
              </div>
            </ng-container>
            <ng-template #fileType>
              <div class="file-attached-card">
                <a type="button" class="download-btn"
                  (click)="generalService.downloadFile(file.img_url, file.file_name)">
                  <div class="file-attached-item">
                    <div class="file-type-info">
                      <div class="file-icon">
                        <img *ngIf="file.file_type === 'csv'" src="assets/images/CSV.svg" alt="file-type" />
                        <img *ngIf="file.file_type === 'pdf'" src="assets/images/PDF.svg" alt="file-type" />
                        <img *ngIf="file.file_type === 'txt'" src="assets/images/TXT.svg" alt="file-type" />
                        <img *ngIf="file.file_type === 'zip'" src="assets/images/ZIP.svg" alt="file-type" />
                        <img *ngIf="!['csv', 'pdf', 'txt', 'zip'].includes(file.file_type)"
                          src="assets/images/pin-attach-icon.svg" alt="file-type" />
                      </div>
                    </div>
                    <div class="file-detail-info">
                      <div class="name">{{ file.file_name }}</div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="form-group" [formGroup]="replyData" *ngIf="isOpen !== 'closed'">
    <angular-editor formControlName="msg" [config]="config"> </angular-editor>
    <div class="row attach-button mt-2">
      <div class="col-md-2">
        <label class="action-link upload-attachment" [for]="'upload-attach' + ticketId" *ngIf="!isVendor">
          <input type="file" #fileUpdload class="d-none" multiple [id]="'upload-attach' + ticketId"
            (change)="handleFileInput($event, ticketId)" />
          <button type="button" class="btn t-primary-btn" (click)="fileUpdload.click()" [title]="getUploadedFileName">
            Attach file(s)
          </button>
          <span *ngIf="selectedFileCount > 0" class="count">
            {{ selectedFileCount }}
          </span>
        </label>
        <label class="action-link upload-attachment" [for]="'upload-attach' + ticketId" *ngIf="isVendor">
          <input type="file" class="d-none" #fileToUpload [id]="'upload-attach' + ticketId"
            (change)="uploadTicketFile($event, ticketId)" />
          <button type="button" class="btn t-primary-btn" (click)="fileToUpload.click()">
            Upload image
          </button>
          <span *ngIf="selectedFileCount > 0" class="count">
            {{ selectedFileCount }}
          </span>
        </label>
      </div>
    </div>
    <!-- <div *ngIf="f['msg'].invalid && f['msg'].touched" class="cross-validation-error-message">
      <div *ngIf="f['msg'].errors && f['msg'].errors['required']" class="required-note text-danger">
        Please enter the message.
      </div>
    </div> -->
  </div>
  <div class="bottom-btnrow" *ngIf="isOpen !== 'closed'" #replyDiv>
    <button type="button" class="btn t-primary-btn for-disabled" (click)="sendMessage()"
      [disabled]="replyData.get('msg').invalid || !replyData.get('msg').value.trim()">
      <img src="assets/images/paper-plane-outline.svg" alt="send" /> Send
    </button>
  </div>
</div>