<div
  class="custom-modal prod-sel-modal"
  id="row-configure-selection"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    role="document"
  >
    <div class="modal-content" style="max-height: 90vh">
      <div class="modal-header">
        <h4 class="modal-title">{{ rowName }}</h4>
        <button type="button" class="close" (click)="close()">
          <img src="assets/images/close-outline.svg" alt="close" />
        </button>
      </div>
      <div class="modal-body">
        <div [formGroup]="homePageForm">
          <div class="form-group row align-items-center">
            <div class="col-6">
              <div class="date-timecol">
                <label for="title" class="form-label fw-bold">
                  Title <span class="required">*</span></label
                >
                <div class="date-control-group">
                  <input
                    appAlphaNumeric
                    [allowSpecialCharacters]="'-'"
                    type="text"
                    class="form-control"
                    formControlName="title"
                  />

                  <div
                    *ngIf="
                      homePageForm.get('title').invalid &&
                      homePageForm.get('title').touched
                    "
                    class="cross-validation-error-message"
                  >
                    <div
                      *ngIf="
                        homePageForm.get('title').errors &&
                        homePageForm.get('title').errors['required']
                      "
                      class="required-note text-danger"
                    >
                      Title is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="date-timecol">
                <label for="title" class="form-label fw-bold">
                  Status <span class="required">*</span></label
                >
                <div class="date-control-group">
                  <ng-select2
                    class="select2-control"
                    name=""
                    id="statusID"
                    [data]="statusList"
                    formControlName="status"
                    [placeholder]="'Select'"
                  >
                  </ng-select2>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row align-items-center">
            <div
              class="col-6"
              *ngIf="
                data.code === contentCode.Category ||
                data.code === contentCode.Banner
              "
            >
              <label for="title" class="form-label fw-bold">
                {{ getTitle }}
              </label>
              <ng-select2
                name="vendor"
                id="vendor"
                class="select2-control"
                [data]="configureData"
                value="id"
                [placeholder]="'Select ' + getTitle"
                formControlName="selectedCategory"
                (ngModelChange)="onCategoryChanges()"
              >
              </ng-select2>
            </div>
            <ng-container
              *ngIf="
                data.code === contentCode.Flash ||
                data.code === contentCode.ServiceGrid ||
                data.code === contentCode.ServiceCarouse ||
                data.code === contentCode.ProductGrid ||
                data.code === contentCode.ProductCarouse
              "
            >
              <div class="col-6">
                <label for="title" class="form-label fw-bold">
                  Category <span class="required">*</span></label
                >
                <ng-select2
                  name="productCat"
                  id="productCat"
                  class="select2-control"
                  [data]="productsData"
                  value="id"
                  [placeholder]="'Select ' + getTitle"
                  formControlName="selectedCategory"
                  (ngModelChange)="getCategoryWiseSubcategorys($event)"
                >
                </ng-select2>
              </div>
              <div class="col-6">
                <label for="title" class="form-label fw-bold">
                  {{ getSubTitle }}<span class="required">*</span>
                </label>
                <ng-select2
                  name="subCategorys"
                  id="subCategorys"
                  class="select2-control"
                  [data]="subCatregoryData"
                  value="id"
                  [placeholder]="'Select ' + getSubTitle"
                  formControlName="selectedSubCategory"
                  (ngModelChange)="onProductCategoryChanges($event)"
                >
                </ng-select2>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="data.code === contentCode.Flash">
            <div class="form-group row align-items-center">
              <!-- Start Date -->
              <div class="col-6">
                <div class="date-timecol">
                  <label for="startDate" class="form-label fw-bold">
                    Start Date
                  </label>
                  <div class="date-control-group">
                    <input
                      type="text"
                      gfdDateRangePicker
                      #startDateRef="DateRangePickerRef"
                      [options]="startDateOptions"
                      class="form-control date-time-control"
                      formControlName="startDate"
                      placeholder="dd/MM/yyyy"
                      autocomplete="off"
                      (ngModelChange)="startTimeChange()"
                    />
                  </div>
                </div>
              </div>
              <!-- End Date -->
              <div class="col-6">
                <div class="date-timecol">
                  <label for="endDate" class="form-label fw-bold">
                    End Date
                  </label>
                  <div class="date-control-group">
                    <input
                      type="text"
                      gfdDateRangePicker
                      #endDateRef="DateRangePickerRef"
                      [options]="endDateOptions"
                      class="form-control date-time-control"
                      formControlName="endDate"
                      placeholder="dd/MM/yyyy"
                      autocomplete="off"
                      (ngModelChange)="startTimeChange()"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row align-items-center">
              <!-- Start Time -->
              <div class="col-6">
                <label for="startTime" class="form-label fw-bold">
                  Start Time
                </label>
                <div class="date-control-group">
                  <input
                    class="form-control type-time date-time-control"
                    placeholder="Start Time"
                    formControlName="startTime"
                    gfdEquipTimePicker
                    #endTimeRef="TimePickerRef"
                    [timeformat]="24"
                    [maxTime]="'23:59'"
                    name="startTime"
                    autocomplete="off"
                    (timeChange)="startTimeChange()"
                  />
                </div>
              </div>
              <!-- End Time -->
              <div class="col-6">
                <label for="endTime" class="form-label fw-bold">
                  End Time
                </label>

                <div class="date-control-group">
                  <input
                    class="form-control type-time date-time-control"
                    placeholder="End Time"
                    formControlName="endTime"
                    gfdEquipTimePicker
                    #endTimeRef="TimePickerRef"
                    [timeformat]="24"
                    [maxTime]="'23:59'"
                    [minTime]="minEndTime"
                    name="endTime"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <div class="form-group row align-items-center">
              <!-- Price -->
              <div class="col-6" *ngIf="data.code === contentCode.Flash">
                <div class="date-timecol">
                  <label for="title" class="form-label fw-bold"> Price </label>
                  <div class="date-control-group">
                    <input
                      type="text"
                      class="form-control"
                      gfdNumbersOnly
                      formControlName="price"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="new-coupon-block">
          <div
            cdkDropList
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            *ngIf="configuration?.length; else noCategorySelected"
          >
            <div
              class="example-box"
              *ngFor="let item of configuration; index as i"
              cdkDrag
            >
              <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="config-width">
                  {{ item?.text }}
                </div>
                <button type="button" class="btn" (click)="removeCategory(i)">
                  <img src="assets/images/trash-icon-red.svg" alt="trash" />
                </button>
              </div>
            </div>
          </div>
          <ng-template #noCategorySelected>
            <div class="text-muted h3 text-center">No Category Selected</div>
          </ng-template>
          <div class="custom-icon" *ngIf="false">
            <div class="row text-center">
              <div class="col-4 mt-4">
                <label for="">Custom Icon</label>
              </div>
            </div>
            <div class="p-3">
              <app-file-upload></app-file-upload>
            </div>
          </div>
          <div class="mt-4" *ngIf="false">
            <button type="button" class="w-100 btn t-primary-light py-3">
              <img
                src="assets/images/plus-icon.svg"
                alt="plus-icon"
                class="pr-3"
              />
              Add more
            </button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="bottom-btnrow mt-4">
          <button
            type="button"
            class="btn t-grey-btn-outline"
            (click)="reset()"
          >
            <img src="assets/images/reset-icon.svg" alt="reset" /> RESET
          </button>
          <button
            type="button"
            class="btn t-primary-btn"
            [disabled]="!configuration?.length"
            (click)="saveChanges()"
          >
            <img src="assets/images/tickmark-white.svg" alt="save" /> SAVE
            CHANGES
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
