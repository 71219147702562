import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import {
  IApiResponse,
  IListApiResponse,
} from '@app/shared/model/api-response.model';
import { CoreApiUrls } from '../api-constant';
import {
  IOrder,
  IProductOrderDetail,
  IServiceOrderDetail,
} from '@app/shared/model/orders.model';

@Injectable({
  providedIn: 'root',
})
export class OrderManagementService {
  constructor(private httpClient: HttpClient) {}

  // Invoices
  getInvoiceList(vendorID: any, payload: any): Observable<any> {
    return this.httpClient.get<any>(
      // `${environment.baseApiUrl}admin/invoicelist/${vendorID}?${payload}`
      `${environment.baseApiUrl}admin/invoice/list?${payload}`
    );
  }

  setInvoice(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/createInvoice`,
      payload
    );
  }

  enableDisableInvoice(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/bulkInvoiceStatusUpdate`,
      payload
    );
  }

  deleteInvoice(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/bulkInvoiceDelete`,
      payload
    );
  }

  viewInvoiceDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      // `${environment.baseApiUrl}admin/viewInvoice/${payload.invoiceID}`
      `${environment.baseApiUrl}admin/invoice/details/${payload.invoiceID}`
    );
  }

  getInvoiceDetails(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/getInvoiceDetails/${payload?.orderID}/${payload?.vendorID}`
    );
  }

  // Order
  getOrderList(payload: string): Observable<IListApiResponse<IOrder>> {
    return this.httpClient.get<IListApiResponse>(
      `${environment.baseApiUrl}${CoreApiUrls.orderUrls.list}?${payload}`
    );
  }

  bulkOrderStatusUpdate(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/bulkProductOrderStatusUpdate`,
      payload
    );
  }

  deleteOrder(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/bulkOrderDelete`,
      payload
    );
  }

  getOrderDetails(
    orderID: string
  ): Observable<IApiResponse<IProductOrderDetail>> {
    return this.httpClient.get<IApiResponse<IProductOrderDetail>>(
      `${environment.baseApiUrl}${CoreApiUrls.orderUrls.detail}/${orderID}`
    );
  }

  getServiceOrderDetails(
    orderID: string
  ): Observable<IApiResponse<IServiceOrderDetail>> {
    return this.httpClient.get<IApiResponse>(
      `${environment.baseApiUrl}${CoreApiUrls.servicestUrls.projectDetail}/${orderID}`
    );
  }

  updateOrderStatus(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/updateOrderStatus/${payload.order_id}`,
      payload
    );
  }

  bulkServiceOrderStatusUpdate(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/bulkServiceOrderStatusUpdate`,
      payload
    );
  }

  getOrderInvoiceList(order_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/orderInvoiceList/${order_id}`
    );
  }

  getClientRequirements(order_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/clientRequirement/${order_id}`
    );
  }

  setOrderTask(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/updateOrderTask/${payload.orderID}`,
      payload
    );
  }

  uploadServiceOrderFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/uploadOrderFile`,
      payload
    );
  }

  uploadServiceOrderTaskFile(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/uploadTaskFile `,
      payload
    );
  }

  // Shipments
  getShipmentList(payload: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/shipment/list?${payload}`
    );
  }

  getShippingDetails(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/orders/${id}/shipping-packages`
    );
  }

  enableDisableShipment(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/bulkShipmentStatusUpdate`,
      payload
    );
  }

  deleteShipment(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/bulkShipmentDelete`,
      payload
    );
  }

  getShipmentDetails(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/shipment/${id}`
    );
  }

  setTracking(payload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseApiUrl}admin/addTrackInfo/${payload.order_id}`,
      payload
    );
  }

  setShipment(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/createShipment`,
      payload
    );
  }

  getOrderShipmentList(order_id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/orderShipmentList/${order_id}`
    );
  }

  viewShipmentDetails(payload: any): Observable<any> {
    // get shipment details in add-shipemnt page
    return this.httpClient.get<any>(
      `${environment.baseApiUrl}admin/getShipmentDetails/${payload?.order_id}/${payload.seller}`
    );
  }

  bulkDeleteShipments(payload: any): Observable<any> {
    return this.httpClient.post(
      `${environment.baseApiUrl}admin/bulkShipmentDelete`,
      payload
    );
  }

  // Email
  sendEmail(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseApiUrl}admin/invoice/sendEmail`,
      payload
    );
  }
}
