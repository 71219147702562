<table cellpadding="0" cellspacing="0" width="100%" style="font-family: Arial; border: 0">
  <tr>
    <td valign="top" align="center" style="padding: 0 15px; border: 0">
      <table cellpadding="0" cellspacing="0" width="1000">
        <!-- Invoice Head -->
        <tr>
          <td valign="top" style="padding: 15px 0 30px 0">
            <table cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="left" valign="middle" style="padding: 0">
                  <img src="assets/images/gfd-logo-new.svg" alt="gfd-logo" style="width: 125px" />
                </td>
                <td align="right" valign="middle" style="padding: 0">
                  <table cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="right" valign="middle" style="
                          font-family: Arial;
                          color: #333131;
                          font-size: 14px;
                          line-height: 16px;
                          font-weight: bold;
                          padding: 0 0 5px 0;
                        ">
                        {{ isVATInvoice ? 'Tax' : '' }} Invoice
                      </td>
                    </tr>
                    <tr>
                      <td align="right" valign="middle" style="
                          font-family: Arial;
                          color: #333131;
                          font-size: 14px;
                          line-height: 16px;
                          font-weight: normal;
                          padding: 0;
                        ">
                        INV#{{ invoiceDetails?.invoice_number }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <!-- Order Info 3 Blocks -->
        <tr>
          <td valign="top" style="padding: 0 0 30px 0; border: 0">
            <table cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="left" valign="top" style="padding: 0 30px 0 0; width: 33.33%">
                  <table cellpadding="0" cellspacing="0" width="100%" style="
                      border: 1px solid #ececec;
                      border-radius: 8px;
                      padding: 15px;
                      border-collapse: initial;
                    ">
                    <tr>
                      <td align="left" valign="top" style="padding: 0 0 15px 0">
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #333131;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                padding: 0 0 5px 0;
                              ">
                              Order Number
                            </td>
                          </tr>
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #8b8b8b;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: normal;
                              ">
                              {{ invoiceDetails?.order_number }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" style="padding: 0 0 15px 0">
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #333131;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                padding: 0 0 5px 0;
                              ">
                              Order Date
                            </td>
                          </tr>
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #8b8b8b;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: normal;
                              ">
                              {{
                              invoiceDetails?.order_date
                              | date
                              : 'dd MMMM YYYY
                              (hh:mm:ss)'
                              }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" style="padding: 0 0 15px 0">
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #333131;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                padding: 0 0 5px 0;
                              ">
                              Tax Invoice Issue Date
                            </td>
                          </tr>
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #8b8b8b;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: normal;
                              ">
                              {{
                              invoiceDetails?.createdAt
                              | date
                              : 'dd MMMM YYYY
                              (hh:mm:ss)'
                              }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top" style="padding: 0">
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #333131;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                padding: 0 0 5px 0;
                              ">
                              Amount Payable
                            </td>
                          </tr>
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #8b8b8b;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: normal;
                              ">
                              AED {{ finalGrandTotal | number : '1.2-2' }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>

                <!-- <td align="left" valign="top" style="padding: 0 30px 0 0; width: 33.33%">
                                    <table cellpadding="0" cellspacing="0" width="100%" style="border: 1px solid #ececec; border-radius: 8px; padding: 15px; border-collapse: initial;">
                                        <tr>
                                            <td align="left" valign="top" style="padding: 0">
                                                <table cellpadding="0" cellspacing="0" width="100%">
                                                    <tr>
                                                        <td align="left" valign="top"
                                                            style="font-family: Arial; color: #333131; font-size: 14px; line-height: 18px; font-weight: bold; padding: 0 0 5px 0;">
                                                            Seller
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="left" valign="top"
                                                            style="font-family: Arial; color: #8b8b8b; font-size: 14px; line-height: 16px; font-weight: normal;">
                                                            Company X <br>
                                                            Dubai Internet City, Dubai <br>
                                                            500637 <br>
                                                            United Arab Emirates <br>
                                                            +971 50 123 4567 <br>
                                                            TRN 1000XXXXXXXXXXX
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td> -->

                <td align="left" valign="top" style="padding: 0; width: 33.33%">
                  <table cellpadding="0" cellspacing="0" width="100%" style="
                      border: 1px solid #ececec;
                      border-radius: 8px;
                      padding: 15px;
                      border-collapse: initial;
                    ">
                    <tr>
                      <td align="left" valign="top" style="padding: 0 0 15px 0">
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #333131;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                padding: 0 0 5px 0;
                              ">
                              Billing Address
                            </td>
                          </tr>
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #8b8b8b;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: normal;
                              ">
                              {{
                              billingAddress?.full_name
                              ? billingAddress?.full_name
                              : billingAddress?.first_name +
                              ' ' +
                              billingAddress?.last_name
                              }}
                              <br />
                              {{
                              billingAddress?.appartment_details +
                              ', ' +
                              billingAddress?.street_name +
                              ', '
                              }}
                              {{
                              billingAddress?.area +
                              ', ' +
                              billingAddress?.city
                              }}<br />
                              {{ billingAddress?.country }} <br />
                              {{
                              billingAddress?.contact_no?.internationalNumber
                              }}
                              <br />
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr *ngIf="invoiceDetails?.type == 1">
                      <td align="left" valign="top" style="padding: 0">
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td align="left" valign="top" style="
                                font-family: Arial;
                                color: #333131;
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: bold;
                                padding: 0 0 5px 0;
                              ">
                              Shipping Address
                            </td>
                          </tr>
                          <tr>
                            <td align="left" valign="top" *ngIf="
                                shippingAddress?.first_name ||
                                shippingAddress?.full_name
                              " style="
                                font-family: Arial;
                                color: #8b8b8b;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: normal;
                              ">
                              {{
                              shippingAddress?.full_name
                              ? shippingAddress?.full_name
                              : shippingAddress?.first_name +
                              ' ' +
                              shippingAddress?.last_name
                              }}
                              <br />
                              {{
                              shippingAddress?.appartment_details
                              ? shippingAddress?.appartment_details + ', '
                              : ''
                              }}
                              {{
                              shippingAddress?.street_name
                              ? shippingAddress?.street_name + ','
                              : ''
                              }}
                              {{
                              shippingAddress?.area
                              ? shippingAddress?.area + ', '
                              : ''
                              }}
                              {{ shippingAddress?.City?.city_name }} <br />
                              {{ shippingAddress?.Country?.country_name }}
                              <br />
                              {{
                              shippingAddress?.contact_no?.internationalNumber
                              }}
                              <br />
                            </td>
                            <td align="left" valign="top" *ngIf="
                                !shippingAddress?.first_name &&
                                !shippingAddress?.full_name
                              " style="
                                font-family: Arial;
                                color: #8b8b8b;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: normal;
                              ">
                              N/A
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <!-- Item Invoice Table -->
        <tr>
          <td valign="top" style="padding: 0 0 30px 0; border: 0">
            <!--Service-->
            <table *ngIf="invoiceDetails?.type == 0" cellpadding="0" cellspacing="0" width="100%">
              <thead>
                <tr style="background-color: #f5f6f8">
                  <th scope="col" align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px;
                      border: 0;
                      white-space: nowrap;
                      width: 225px;
                    ">
                    Item Name
                  </th>
                  <th scope="col" align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border: 0;
                      white-space: nowrap;
                      width: 200px;
                    ">
                    Item Code
                  </th>
                  <th scope="col" align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border: 0;
                      white-space: nowrap;
                      width: 200px;
                    ">
                    Seller
                  </th>
                  <th scope="col" align="left" valign="top" *ngIf="invoiceDetails?.type == 1" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border: 0;
                      white-space: nowrap;
                    ">
                    Qty
                  </th>
                  <th scope="col" align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border: 0;
                      white-space: nowrap;
                    ">
                    Price (AED) <br />
                  </th>
                  <th scope="col" align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px;
                      border: 0;
                      white-space: nowrap;
                    ">
                    Total Price (AED) <br />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{ invoiceDetails?.service?.name }} <br />
                    <span style="
                        font-size: 12px;
                        color: #8b8b8b;
                        font-weight: normal;
                      ">{{ invoiceDetails?.service?.packages?.title }}</span>
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    invoiceDetails?.service?.sku
                    ? invoiceDetails?.service?.sku
                    : '-'
                    }}
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    invoiceDetails?.service?.seller_company_name
                    ? invoiceDetails?.service?.seller_company_name
                    : '-'
                    }}
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    invoiceDetails?.service?.packages?.sale_price
                    ? (invoiceDetails?.service?.packages?.sale_price
                    | number : '1.2-2')
                    : 0.0
                    }}
                    <br />
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    invoiceDetails?.service?.subtotal
                    ? (invoiceDetails?.service?.subtotal | number : '1.2-2')
                    : 0.0
                    }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="6" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 10px;
                      border-top: 1px solid #333131;
                    "></td>
                  <td *ngIf="invoiceDetails?.type == 0" colspan="5" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 10px;
                      border-top: 1px solid #333131;
                    "></td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="5" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Subtotal
                  </td>
                  <td *ngIf="invoiceDetails?.type == 0" colspan="4" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Subtotal
                  </td>
                  <td align="left" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    finalSubtotal ? (finalSubtotal | number : '1.2-2') : 0.0
                    }}
                  </td>
                </tr>
                <tr *ngIf="isVATInvoice">
                  <td *ngIf="invoiceDetails?.type == 1" colspan="5" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    VAT
                  </td>
                  <td *ngIf="invoiceDetails?.type == 1" align="left" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{ finalTax ? (finalTax | number : '1.2-2') : 0.0 }}
                  </td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="5" align="right" valign="top" style="
                      color: #ee0404;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Discount
                  </td>
                  <td *ngIf="invoiceDetails?.type == 0" colspan="4" align="right" valign="top" style="
                      color: #ee0404;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Discount
                  </td>
                  <td align="left" valign="top" style="
                      color: #ee0404;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    finalDiscount ? (finalDiscount | number : '1.2-2') : 0.0
                    }}
                  </td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="5" align="right" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Amount Payable
                  </td>
                  <td *ngIf="invoiceDetails?.type == 0" colspan="4" align="right" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Amount Payable
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    finalGrandTotal
                    ? (finalGrandTotal | number : '1.2-2')
                    : 0.0
                    }}
                  </td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="5" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Payment Type
                  </td>
                  <td *ngIf="invoiceDetails?.type == 0" colspan="4" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Payment Type
                  </td>
                  <td align="left" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    invoiceDetails?.payment_type
                    ? invoiceDetails?.payment_type
                    : '-'
                    }}
                  </td>
                </tr>
                <!-- <tr>
                                    <td style="padding: 20px 0 0 0; border-top: 1px solid #333131;">
                                        <table cellpadding="0" cellspacing="0" width="100%">
                                        </table>
                                    </td>
                                </tr> -->
              </tfoot>
            </table>

            <!--Product-->
            <table *ngIf="invoiceDetails?.type == 1" cellpadding="0" cellspacing="0" width="100%">
              <thead>
                <tr style="background-color: #f5f6f8">
                  <th scope="col" align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px;
                      border: 0;
                      white-space: nowrap;
                      width: 225px;
                    ">
                    Item Name
                  </th>
                  <th scope="col" align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border: 0;
                      white-space: nowrap;
                      width: 200px;
                    ">
                    Item Code
                  </th>
                  <th scope="col" align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border: 0;
                      white-space: nowrap;
                      width: 200px;
                    ">
                    Seller
                  </th>
                  <th scope="col" align="left" valign="top" *ngIf="invoiceDetails?.type == 1" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border: 0;
                      white-space: nowrap;
                    ">
                    Qty
                  </th>
                  <th scope="col" align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border: 0;
                      white-space: nowrap;
                    ">
                    Price (AED) <br />
                  </th>
                  <th scope="col" align="left" valign="top" *ngIf="!isVATInvoice" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px;
                      border: 0;
                      white-space: nowrap;
                    ">
                    Total Price (AED) <br />
                    <span style="
                        font-size: 10px;
                        font-weight: normal;
                        color: #8b8b8b;
                      ">excl. VAT</span>
                  </th>
                  <th scope="col" align="left" valign="top" *ngIf="isVATInvoice" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border: 0;
                      white-space: nowrap;
                    ">
                    VAT Amount (AED - Per Unit)
                  </th>
                  <th scope="col" align="left" valign="top" *ngIf="isVATInvoice" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 14px;
                      padding: 10px;
                      border: 0;
                      white-space: nowrap;
                    ">
                    Total Price (AED) <br />
                    <span style="
                        font-size: 10px;
                        font-weight: normal;
                        color: #8b8b8b;
                      ">incl. VAT</span>
                  </th>
                  <!-- <th  align="left" valign="top" *ngIf="isVATInvoice"
                                        style="color: #333131; font-size: 12px; font-weight: bold; line-height: 14px; padding: 10px; border: 0; white-space: nowrap;">
                                        Total Price (AED) <br />
                                    </th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of invoiceDetails?.product">
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    item?.variants?.name
                    ? item?.product_name + ' - ' + item?.variants?.name
                    : item?.product_name
                    }}
                    <br />
                    <!-- <span style="font-size: 12px; color: #8b8b8b; font-weight: normal;">Standard Package</span> -->
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    item?.variants?.sku
                    ? item?.variants?.sku
                    : item?.product_sku
                    }}
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    item?.seller_company_name
                    ? item?.seller_company_name
                    : '-'
                    }}
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{ item?.product_quantity }}
                  </td>
                  <td align="left" valign="top" *ngIf="item?.variants?.price" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    item?.variants?.sale_price &&
                    item?.variants?.sale_price !== ''
                    ? (item?.variants?.sale_price | number : '1.2-2')
                    : (item?.variants?.price | number : '1.2-2')
                    }}
                    <br />
                  </td>
                  <td align="left" valign="top" *ngIf="!item?.variants?.price" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{ item?.product_price | number : '1.2-2' }}
                    <br />
                  </td>
                  <td align="left" valign="top" *ngIf="isVATInvoice" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px 10px 10px 0;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    item?.tax_price
                    ? (item?.tax_price | number : '1.2-2')
                    : 0.0
                    }}
                  </td>
                  <td align="left" valign="top" *ngIf="!isVATInvoice" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    item?.total_excluding_vat
                    ? (item?.total_excluding_vat | number : '1.2-2')
                    : 0.0
                    }}
                    <br />
                    <span *ngIf="item?.variants?.price" style="
                        font-size: 12px;
                        color: #8b8b8b;
                        font-weight: normal;
                      ">({{
                      item?.variants?.sale_price &&
                      item?.variants?.sale_price !== ''
                      ? (item?.variants?.sale_price | number : '1.2-2')
                      : item?.variants?.price
                      }}/unit)</span>
                    <span *ngIf="!item?.variants?.price" style="
                        font-size: 12px;
                        color: #8b8b8b;
                        font-weight: normal;
                      ">({{
                      item?.product_price
                      ? (item?.product_price | number : '1.2-2')
                      : 0
                      }}/unit)</span>
                  </td>
                  <td align="left" valign="top" *ngIf="isVATInvoice" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 10px;
                      border-bottom: 1px solid #f5f5f5;
                    ">
                    {{
                    item?.total_including_vat
                    ? (item?.total_including_vat | number : '1.2-2')
                    : 0.0
                    }}
                    <br />
                    <span *ngIf="item?.variants?.price" style="
                        font-size: 12px;
                        color: #8b8b8b;
                        font-weight: normal;
                      ">({{
                      item?.variants?.sale_price &&
                      item?.variants?.sale_price !== ''
                      ? (item?.variants?.sale_price | number : '1.2-2')
                      : item?.variants?.price
                      }}/unit)</span>
                    <span *ngIf="!item?.variants?.price" style="
                        font-size: 12px;
                        color: #8b8b8b;
                        font-weight: normal;
                      ">({{
                      item?.product_price
                      ? (item?.product_price | number : '1.2-2')
                      : 0
                      }}/unit)</span>
                  </td>
                </tr>
              </tbody>
              <tfoot *ngIf="isVATInvoice">
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="6" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 10px;
                      border-top: 1px solid #333131;
                    "></td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="6" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Subtotal
                  </td>
                  <td align="left" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    finalSubtotal ? (finalSubtotal | number : '1.2-2') : 0.0
                    }}
                  </td>
                </tr>
                <tr *ngIf="isVATInvoice">
                  <td *ngIf="invoiceDetails?.type == 1" colspan="6" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    VAT
                  </td>
                  <td align="left" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{ finalTax ? (finalTax | number : '1.2-2') : 0.0 }}
                  </td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="6" align="right" valign="top" style="
                      color: #ee0404;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Discount
                  </td>
                  <td align="left" valign="top" style="
                      color: #ee0404;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    finalDiscount ? (finalDiscount | number : '1.2-2') : 0.0
                    }}
                  </td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="6" align="right" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Amount Payable
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    finalGrandTotal
                    ? (finalGrandTotal | number : '1.2-2')
                    : 0.0
                    }}
                  </td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="6" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Payment Type
                  </td>
                  <td align="left" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    invoiceDetails?.payment_type
                    ? invoiceDetails?.payment_type
                    : '-'
                    }}
                  </td>
                </tr>
              </tfoot>
              <tfoot *ngIf="!isVATInvoice">
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="6" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 10px;
                      border-top: 1px solid #333131;
                    "></td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="5" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Subtotal
                  </td>
                  <td align="left" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    finalSubtotal ? (finalSubtotal | number : '1.2-2') : 0.0
                    }}
                  </td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="5" align="right" valign="top" style="
                      color: #ee0404;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Discount
                  </td>
                  <td align="left" valign="top" style="
                      color: #ee0404;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    finalDiscount ? (finalDiscount | number : '1.2-2') : 0.0
                    }}
                  </td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="5" align="right" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Amount Payable
                  </td>
                  <td align="left" valign="top" style="
                      color: #333131;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    finalGrandTotal
                    ? (finalGrandTotal | number : '1.2-2')
                    : 0.0
                    }}
                  </td>
                </tr>
                <tr>
                  <td *ngIf="invoiceDetails?.type == 1" colspan="5" align="right" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 16px;
                      padding: 5px 10px 5px 0;
                      border: 0;
                    ">
                    Payment Type
                  </td>
                  <td align="left" valign="top" style="
                      color: #8b8b8b;
                      font-size: 12px;
                      font-weight: normal;
                      line-height: 14px;
                      padding: 5px 10px;
                      border: 0;
                    ">
                    {{
                    invoiceDetails?.payment_type
                    ? invoiceDetails?.payment_type
                    : '-'
                    }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </td>
        </tr>
      </table>
      <table cellpadding="0" cellspacing="0" width="1000" style="margin: 30px 0 0 0">
        <tr>
          <td colspan="2" align="left" valign="top" style="
              font-family: Arial;
              color: #333131;
              font-size: 14px;
              line-height: 16px;
              font-weight: normal;
              padding: 0 0 10px 0;
              border-bottom: 1px solid #ececec;
            ">
            This is a computer generated document and does not require
            signature. Consideration charged includes prices of all items.
            <br />
            Terms & Conditions Apply. If you hold a VAT registration number,
            please contact customer support to be issued a full Tax Invoice.
          </td>
        </tr>
        <tr>
          <td align="left" valign="bottom" style="
              font-family: Arial;
              color: #8b8b8b;
              font-size: 12px;
              line-height: 14px;
              font-weight: normal;
              padding: 10px 0 0 0;
            ">
            Thanks for booking with us! We appreciate your order. <br />
            For any questions or comments, you can contact our customer support
            team at <br />
            Email:
            <a href="mailto:support@meydanplus.com"
              style="color: #8b8b8b; text-decoration: none; font-weight: bold">support@meydanplus.com</a>
            | Phone:
            <a href="tel:800-123456" style="color: #8b8b8b; text-decoration: none; font-weight: bold">800-MEYDAN
              (800-123456)</a>
            between 9:00AM to 11:00PM, 7 days a week.
          </td>
          <td *ngIf="invoiceDetails?.customer?.tax_registration_no" align="right" valign="bottom" style="
              font-family: Arial;
              color: #8b8b8b;
              font-size: 12px;
              line-height: 14px;
              font-weight: normal;
              padding: 10px 0 0 0;
            ">
            TRN {{ invoiceDetails?.customer?.tax_registration_no }}
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>