import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export class ValidationService {
  static required(control: AbstractControl): { [key: string]: boolean } | null {
    if (
      control.value === undefined ||
      control.value === null ||
      control.value.toString().trim() === '' ||
      control.value.length === 0
    ) {
      return { required: true };
    }
    return null;
  }

  static digitsValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^\d+$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { invalidDigits: true };
    }
    return null;
  }

  static numberValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^[0-9]+(.[0-9]+)?$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { invalidNumber: true };
    }
    return null;
  }

  static lettersOnlyValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^[a-zA-Z]+$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { lettersOnly: true };
    }
    return null;
  }

  static lettersSpaceUnderScoreHyphenValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^[a-zA-Z _-]+$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { letterSpaceUnderscoreHyphen: true };
    }
    return null;
  }

  static lettersNumbersSpaceUnderScoreHyphenValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^[0-9a-zA-Z _-]+$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { lettersNumbersSpaceUnderScoreHyphen: true };
    }
    return null;
  }

  static noWhiteSpaceValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^\S+$/i;
    const controlValue = control.value || '';
    if (controlValue && !reg.test(controlValue)) {
      return { whiteSpace: true };
    }
    return null;
  }

  static alphaWithSpaceValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^[a-zA-Z ]+$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { alphaSpace: true };
    }
    return null;
  }

  static alphaNumericValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^[0-9a-zA-Z]+$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { alphaNumeric: true };
    }
    return null;
  }

  static alphaNumericWithSpaceValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^[0-9a-zA-Z ]+$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { alphaNumericSpace: true };
    }
    return null;
  }

  static ipAddressValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg =
      /^(1?d{1,2}|2([0-4]d|5[0-5]))(.(1?d{1,2}|2([0-4]d|5[0-5]))){3}$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { ipAddress: true };
    }
    return null;
  }

  static creditCardValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // eslint-disable-next-line max-len
    const reg =
      /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { creditCard: true };
    }
    return null;
  }

  static zipCodeValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { zipCode: true };
    }
    return null;
  }

  static alphaNumericCompulsoryValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /[a-z].*[0-9]|[0-9].*[a-z]/i;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { alphaNumericCompulsory: true };
    }
    return null;
  }

  static alphaNumericCapitalCompulsoryValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { alphaNumericCapitalCompulsory: true };
    }
    return null;
  }

  static alphaNumericSpecialCompulsoryValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const reg =
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*?_-])[a-zA-Z0-9!@#$%^&*?_-]{3,}$/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { alphaNumericSpecialCompulsory: true };
    }
    return null;
  }

  static alphaNumericCapitalAndSpecialCompulsoryValidator(): ValidatorFn {
    const validator = (
      control: AbstractControl
    ): { [key: string]: boolean } | null => {
      // (?=.*[\d])       - Assert a string has at least one number
      // (?=.*[A-Z])      - Assert a string has at least one capital
      // (?=.*[a-z])      - Assert a string has at least one lowercase
      const regStr = `^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$`;
      const reg = new RegExp(regStr);
      const controlValue = control.value && String(control.value).trim();
      if (controlValue && !reg.test(controlValue)) {
        return { alphaNumericCapitalSpecialCompulsory: true };
      }
      return null;
    };
    return validator;
  }

  static minValidator(min: number): ValidatorFn {
    const validator = (
      control: AbstractControl
    ): { [key: string]: boolean } | null => {
      const controlValue = control.value && String(control.value).trim();
      if (controlValue && +controlValue < min) {
        return {
          min: true,
        };
      }
      return null;
    };
    return validator;
  }

  static maxValidator(max: number): ValidatorFn {
    const validator = (
      control: AbstractControl
    ): { [key: string]: boolean } | null => {
      const controlValue = control.value && String(control.value).trim();
      if (controlValue && +controlValue > max) {
        return {
          max: true,
        };
      }
      return null;
    };
    return validator;
  }

  static rangeValidator(min: number, max: number): ValidatorFn {
    const validator = (
      control: AbstractControl
    ): { [key: string]: boolean } | null => {
      const controlValue = control.value && String(control.value).trim();
      if (
        controlValue &&
        (controlValue.toString().length < min ||
          controlValue.toString().length > max)
      ) {
        return {
          range: true,
        };
      }
      return null;
    };
    return validator;
  }

  static rangeLengthValidator(
    minLength: number,
    maxLength: number
  ): ValidatorFn {
    const validator = (
      control: AbstractControl
    ): { [key: string]: boolean } | null => {
      const controlValue = control.value && String(control.value).trim();
      if (
        controlValue &&
        (control.value.toString().length < minLength ||
          control.value.toString().length > maxLength)
      ) {
        return {
          rangeLength: true,
        };
      }
      return null;
    };
    return validator;
  }

  static minLengthValidator(minLength: number): ValidatorFn {
    const validator = (
      control: AbstractControl
    ): { [key: string]: any } | null => {
      const controlValue = control.value && String(control.value).trim();
      if (controlValue && controlValue.length < minLength) {
        return {
          minLength: {
            actualLength: control.value?.length || 0,
            requiredLength: minLength,
          },
        };
      }
      return null;
    };
    return validator;
  }

  static maxLengthValidator(maxLength: number): ValidatorFn {
    const validator = (
      control: AbstractControl
    ): { [key: string]: any } | null => {
      const controlValue = control.value && String(control.value).trim();
      if (controlValue && controlValue.length > maxLength) {
        return {
          maxLength: {
            actualLength: control.value?.length || 0,
            requiredLength: maxLength,
          },
        };
      }
      return null;
    };
    return validator;
  }

  static greaterThanZeroValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const controlValue = control.value && String(control.value).trim();

    if (+controlValue <= 0) {
      return { greaterThanZero: true };
    }

    return null;
  }

  static emailValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // RFC 2822 compliant regex
    // eslint-disable-next-line max-len
    const reg =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const controlValue = control.value && String(control.value).trim();
    if (controlValue && !reg.test(controlValue)) {
      return { email: true };
    }
    return null;
  }

  static passwordValidator(min: number = 6, max: number = 15): ValidatorFn {
    const validator = (
      control: AbstractControl
    ): { [key: string]: boolean } | null => {
      // (?=.*[\d])       - Assert a string has at least one number
      // (?=.*[A-Z])      - Assert a string has at least one capital
      // (?=.*[a-z])      - Assert a string has at least one lowercase
      // {6,15}           - Assert password is between 6 and 15 characters
      const regStr = `^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\\w!@#$%^&*]{${min},${max}}$`;
      const reg = new RegExp(regStr);
      const controlValue = control.value && String(control.value).trim();
      if (controlValue && !reg.test(controlValue)) {
        return { password: true };
      }
      return null;
    };
    return validator;
  }

  static urlValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    // https://gist.github.com/dperini/729294
    // see also https://mathiasbynens.be/demo/url-regex
    // modified to allow protocol-relative URLs
    // eslint-disable-next-line max-len
    const reg =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    const url = control.value && String(control.value).trim();
    if (url && !reg.test(url)) {
      return { url: true };
    }
    return null;
  }
  static isLowerValidator(comparedControl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const min = +control?.root?.get(comparedControl)?.value;
      const max = +control.value;

      return min && max && max <= min ? { lowValue: true } : null;
    };
  }

  static isLowerValidatorInFormArray(comparedControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parentFormGroup = control.parent as FormGroup;

      if (parentFormGroup) {
        const min = +parentFormGroup.get(comparedControlName)?.value;
        const max = +control.value;
        return min && max && max <= min ? { lowValue: true } : null;
      }
      return null;
    };
  }

  static emptyContentValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    const strippedValue = value.replace(/<[^>]+>/g, '').trim(); // Remove HTML tags and trim whitespace
    return strippedValue.length === 0 ? { required: true } : null;
  }

}
