import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { StorageService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private coreService: StorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the URL contains the 'skipAuth' query parameter
    if (request.url.includes('skipAuth=true')) {
      console.log('skipped auth');
      // Clone the request and remove the query parameter before forwarding
      const authReq = request.clone({
        url: request.url.replace('skipAuth=true', ''),
      });
      return next.handle(authReq);
    }

    let authReq = request;
    const headers = this.getHeaders();

    if (Object.keys(headers).length) {
      authReq = request.clone({
        setHeaders: headers,
      });
    }

    return next.handle(authReq);
  }

  private getHeaders(): { [key: string]: string } {
    const token = this.coreService.getCookie('AccessToken');

    const headers: { [key: string]: string } = {};

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return headers;
  }
}
