<header class="header-wrapper">
  <nav class="navigation-bar">
    <div class="navbar-container">
      <div class="left-side">
        <button type="button" class="navbar-toggler" type="button">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div class="head-search-box" [ngClass]="{ active: isResultBoxAppears }">
          <input
            type="text"
            class="form-control"
            placeholder="Search"
            [(ngModel)]="searchKey"
            (ngModelChange)="changeSearchKey()"
            (focus)="onSearchTextFocusChange(true)"
            (focusout)="onSearchTextFocusChange(false)"
          />
          <button type="button" class="btn search-btn t-primary-btn">
            <img src="assets/images/search-icon-white.svg" alt="search-icon" />
          </button>
          <span
            role="button"
            class="clear"
            *ngIf="searchKey"
            (click)="clearSearch()"
          >
            <img src="assets/images/close-grey.svg" alt="search-icon" />
          </span>
          <div class="search-result-box">
            <ul>
              <li *ngFor="let item of resultBoxOptions">
                <a href="javascript: void(0)" (click)="onOptionSelect(item)">
                  <span>{{ searchKey }}</span> {{ item.text }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="navgiation-block">
          <ul class="notification-list-group">
            <app-notifications></app-notifications>
            <li class="notification-item email">
              <a
                (click)="gotoTicket()"
                [routerLink]="['/home/support/tickets']"
              >
                <img src="assets/images/mail-icon.svg" alt="notified-icon" />
                <!-- <span class="notification-badge"></span> -->
              </a>
            </li>
            <li class="user-type">
              <div class="content">
                <div class="name">{{ profileDetails?.full_name }}</div>
                <span *ngIf="role_name" class="acc-type">{{ role_name }}</span>
              </div>
              <div class="avatar-img">
                <a [routerLink]="['/home/account-settings/profile']">
                <img
                  [src]="
                    profileDetails?.admin_profile
                      ? profileDetails?.admin_profile
                      : 'assets/images/profile-avatar-img.svg'
                  "
                  alt="avatar-img"
                />
                <span class="notification-badge"></span>
              </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>
