import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { takeUntil, tap } from 'rxjs';

import { AccountSettingsService } from '../../../core/services/account-settings.service';
import { GeneralService } from '../../../core/services/general.service';
import { StorageService } from '../../../core/services/storage.service';
import { VendorService } from '../../../core/services/vendor.service';

import { SubscriptionDisposerComponent } from '../../helpers/subscription-disposer';
import { CredentialService } from '@app/core/services/credential.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  profileDetails: any;
  role_name: any = '';
  searchKey = '';
  isResultBoxAppears = false;
  resultBoxOptions = [
    { id: '1', text: 'in Products', url: '/home/product/products' },
    { id: '2', text: 'in Services', url: '/home/service/services' },
    { id: '3', text: 'in Orders', url: '/home/product/orders' },
    { id: '4', text: 'in Projects', url: '/home/service/projects' },
  ];

  constructor(
    public generalService: GeneralService,
    private storageService: StorageService,
    private credentialService: CredentialService,
    private router: Router,
    private vendorService: VendorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getGlobaslSearchKey();

    this.role_name = this.storageService.getRoleAccessDetails('role_name');
    this.profileDetails = this.credentialService.userDetails;

    this.generalService.notifyObservable$
      .pipe(
        takeUntil(this.destroyed$),
        tap(({ option, value }) => {
          if (option === 'role_name') {
            this.role_name = value;
          }
        })
      )
      .subscribe();
  }

  changeSearchKey(): void {
    if (this.searchKey) {
      this.isResultBoxAppears = true;
    } else {
      this.isResultBoxAppears = false;
    }
  }

  onOptionSelect(item: any): void {
    this.router.navigate([item.url], { queryParams: { gs: this.searchKey } });
    this.isResultBoxAppears = false;
  }

  onSearchTextFocusChange(flag: boolean): void {
    if (this.searchKey) {
      this.isResultBoxAppears = flag;
    } else {
      this.isResultBoxAppears = false;
    }
  }

  getGlobaslSearchKey(): void {
    this.vendorService.getGlobalSearchKey().subscribe((val: string) => {
      if (val) {
        this.searchKey = val;
      } else {
        this.searchKey = '';
      }
    });
  }

  gotoTicket(): void {
    this.vendorService.setHeaderEmailNotification(true);
  }

  clearSearch() {
    this.searchKey = '';
    this.changeSearchKey();
  }
}
