import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { PositionStrategy } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { Observable, forkJoin } from 'rxjs';

import { IApiResponse } from '../model/api-response.model';

/**
 * import OpenModalConfig interface in component for config.
 */
export interface OpenModalConfig {
  modalComponent: any;
  modalData?: { [key: string]: any };
  asyncDataConfig?: AsyncDataConfig | AsyncDataConfig[];
  panelClass?: string[];
  width?: string;
  height?: string;
  positionStrategy?: PositionStrategy;
  disableCloseOnBackdrop?: boolean;
}

export interface AsyncDataConfig {
  api: string;
  dataKey: string;
}
@Directive({
  selector: '[appOpenModal]',
})
export class OpenModalDirective {
  @Input() config!: OpenModalConfig;
  @Output() onModalClose: EventEmitter<any> = new EventEmitter();
  @Output() modalDialogRef: EventEmitter<DialogRef<any, any>> =
    new EventEmitter();
  dialogRef!: DialogRef;
  constructor(private dialog: Dialog, private http: HttpClient) {}
  @HostListener('click', ['$event']) manageLinkModal(): void {
    if (this.config?.asyncDataConfig) {
      this.manageAsyncData();
    } else {
      this.openModal();
    }
  }

  private openModal() {
    if (this.config) {
      let dialogConfig: DialogConfig<unknown, DialogRef<unknown, unknown>> = {
        height: this.config.height || '100vh',
        width: this.config.width || '100vw',
        autoFocus: undefined,
        panelClass: ['dialog-scroll', ...(this.config.panelClass || '')],
        data: this.config.modalData,
      };
      
      if (this.config?.positionStrategy) {
        dialogConfig = {
          ...dialogConfig,
          positionStrategy: this.config.positionStrategy,
        };
      }

      /**
       * disable close on backdrop click
       */
      if (this.config.disableCloseOnBackdrop) {
        dialogConfig = {
          ...dialogConfig,
          disableClose: true,
        };
      }

      this.dialogRef = this.dialog.open(
        this.config.modalComponent,
        dialogConfig
      );
      this.modalDialogRef.emit(this.dialogRef);

      // Listen for the operationSuccess event emitted from the modal
      // this.dialogRef.componentInstance.operationSuccess.subscribe(() => {
      //   this.onModalClose.emit(); // Emit event to parent when operation succeeds
      // });


      this.dialogRef?.closed.subscribe((data) => {
        this.onModalClose.emit(data);
      });
    }
  }

  private manageAsyncData() {
    const apiUrl: { [key: string]: Observable<any> } = {};
    if (Array.isArray(this.config?.asyncDataConfig)) {
      this.config?.asyncDataConfig.forEach((config) => {
        apiUrl[config.dataKey] = this.http.get(config.api);
      });

      forkJoin(apiUrl).subscribe((res: { [key: string]: IApiResponse }) => {
        for (const item in res) {
          if (res && res[item]?.success) {
            this.config.modalData = {
              ...this.config.modalData,
              [item]: res[item].data,
            };
          }
        }
        this.openModal();
      });
    } else {
      this.http
        .get<IApiResponse>(this.config?.asyncDataConfig.api)
        .subscribe((res: IApiResponse) => {
          if (res?.data && res?.success) {
            this.config['modalData'] = {
              ...this.config?.modalData,
              [(this.config?.asyncDataConfig as AsyncDataConfig).dataKey]:
                res.data,
            };
            this.openModal();
          }
        });
    }
  }
}
