import { AccountSettingsService } from '../services/account-settings.service';
import { CredentialService } from '../services/credential.service';
import { StorageService } from '../services/storage.service';

export function UserIdentityInitializerFactory(
  storageService: StorageService,
  credentialService: CredentialService,
  accountSettingsService: AccountSettingsService
): () => Promise<any> {
  return (): Promise<any> =>
    new Promise((resolve) => {
      if (storageService.getCookie(`AccessToken`)) {
        accountSettingsService
          .getUserIdentityDetails()
          .subscribe((response: any) => {
            if (response && response.success) {
              credentialService.setUserDetails(response?.data);
              resolve(true);
            } else {
              resolve(true);
            }
          });
      } else {
        resolve(true);
      }
    });
}
