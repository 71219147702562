import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GenericService {
  constructor(private http: HttpClient) {}

  // CREATE
  createEntity<T>(
    apiEndPoint: string,
    objToCreate: T | any,
    pathParams?: Record<string, string>,
    queryParams?: Record<string, unknown>
  ): Observable<T> {
    const url = this.interpolateUrl(apiEndPoint, pathParams, queryParams);
    return this.http.post<T>(`${url}`, objToCreate, {
      params: this.getQueryParams(queryParams),
    });
  }

  // GET
  getEntityDetails<T>(
    apiEndPoint: string,
    pathParams?: Record<string, string>,
    queryParams?: Record<string, unknown>
  ): Observable<T> {
    const url = this.interpolateUrl(apiEndPoint, pathParams, queryParams);
    return this.http.get<T>(`${url}`, {
      params: this.getQueryParams(queryParams),
    });
  }

  // UPDATE
  updateEntity<T>(
    apiEndPoint: string,
    objToUpdate: T | any,
    pathParams?: Record<string, string>,
    queryParams?: Record<string, unknown>
  ): Observable<T> {
    const url = this.interpolateUrl(apiEndPoint, pathParams, queryParams);
    return this.http.put<T>(`${url}`, objToUpdate);
  }

  // UPDATE
  patchEntity<T>(
    apiEndPoint: string,
    objToUpdate: T | any,
    pathParams?: Record<string, string>,
    queryParams?: Record<string, unknown>
  ): Observable<T> {
    const url = this.interpolateUrl(apiEndPoint, pathParams, queryParams);
    return this.http.patch<T>(`${url}`, objToUpdate, {
      params: this.getQueryParams(queryParams),
    });
  }

  fileDownaload(url: string): Observable<Blob> {
    const skipAuthUrl = `${url}?skipAuth=true`;
    return this.http.get(skipAuthUrl, { responseType: 'blob' });
  }
  //DELETE
  deleteEntity<T>(
    apiEndPoint: string,
    pathParams?: Record<string, string>,
    queryParams?: Record<string, unknown>
  ): Observable<T> {
    const url = this.interpolateUrl(apiEndPoint, pathParams, queryParams);
    return this.http.delete<T>(`${url}`, {
      params: this.getQueryParams(queryParams),
    });
  }

  private getQueryParams(queryParams?: Record<string, any>) {
    let httpQueryParams = new HttpParams();
    if (queryParams) {
      for (const queryParam in queryParams) {
        if (Object.prototype.hasOwnProperty.call(queryParams, queryParam)) {
          httpQueryParams = httpQueryParams.append(
            queryParam,
            queryParams[queryParam]
          );
        }
      }
    }
    return httpQueryParams;
  }

  private interpolateUrl(
    url: string,
    pathParams?: Record<string, string>,
    queryParams?: Record<string, unknown>
  ): string {
    let interpolatedStr = url;

    interpolatedStr = this.replacePathParams(interpolatedStr, pathParams);
    interpolatedStr = this.replaceQueryParams(interpolatedStr, queryParams);
    return interpolatedStr;
  }

  public onTeamMateModalOpen$:BehaviorSubject<any> = new BehaviorSubject(""); 

  /**
   * Replace path params
   * @param interpolatedStr
   * @param pathParams
   * @returns
   */
  private replacePathParams(
    interpolatedStr: string,
    pathParams?: Record<string, string>
  ) {
    if (pathParams) {
      for (const [param, value] of Object.entries(pathParams)) {
        interpolatedStr = interpolatedStr.replace(`[${param}]`, value);
      }
    }
    return interpolatedStr;
  }

  /**
   * replace query params
   * @param interpolatedStr
   * @param queryParams
   * @returns
   */
  private replaceQueryParams(
    interpolatedStr: string,
    queryParams?: Record<string, unknown>
  ) {
    if (queryParams) {
      for (const queryParam in queryParams) {
        if (Object.prototype.hasOwnProperty.call(queryParams, queryParam)) {
          interpolatedStr = interpolatedStr.replace(
            `[${queryParam}]`,
            `${queryParam}=${queryParams[queryParam]}`
          );
        }
      }
    }
    return interpolatedStr;
  }
}
