<div class="page-content-section">
  <div class="middle-content-container">
    <div class="notifications-list-section">
      <div class="page-main-title">
        <h2>Notifications</h2>
        <div class="multiple-links">
          <a
            [routerLink]="['/home/dashboard']"
            class="btn t-grey-btn-outline light-grey"
          >
            <img src="assets/images/back-icon-light.svg" alt="back-icon" /> Go
            back
          </a>
        </div>
      </div>
      <div class="notification-all-wrapper">
        <div class="notification-filter">
          <ul class="tabs">
            <li
              class="tab-link {{ filterID == 0 ? 'active' : '' }}"
              data-tab="all"
            >
              <a href="javascript:void(0);" (click)="filterApply(0)">
                All <span class="total-count">{{ allCount }}</span>
              </a>
            </li>
            <li
              class="tab-link {{ filterID == 1 ? 'active' : '' }}"
              data-tab="unread"
            >
              <a href="javascript:void(0);" (click)="filterApply(1)">
                Unread <span class="total-count">{{ unreadCount }}</span>
              </a>
            </li>
            <li
              class="tab-link {{ filterID == 2 ? 'active' : '' }}"
              data-tab="request"
              (click)="filterApply(2)"
            >
              <a href="javascript:void(0);">
                Request <span class="total-count">{{ requestCount }}</span>
              </a>
            </li>
          </ul>
          <div class="more-action-dd">
            <button
              class="action-more-btn"
              type="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="assets/images/icon-grey-more-option-h.svg"
                alt="more-option"
              />
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li>
                <a href="javascript: void(0)" (click)="markAllAsRead()"
                  >Mark all as read</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="notification-tab-content">
          <div class="tab-info">
            <div class="tab-content current" id="all">
              <div
                class="notification-group"
                *ngIf="filterID == 0 || filterID == 1 || filterID == 2"
              >
                <div
                  (click)="markAsReadNotification(n?._id)"
                  class="notification-info {{ !n.is_read ? 'recent' : '' }}"
                  *ngFor="let n of notificationsLists"
                >
                  <div class="details">
                    <div class="img-icon">
                      <img
                        *ngIf="n?.customer_profile"
                        [src]="n?.customer_profile ? n?.customer_profile : ''"
                        alt="user"
                      />
                    </div>
                    <div class="content">
                      <p *ngIf="!n.is_read">
                        <strong>{{ n?.name }}</strong> {{ n?.module_event }}
                        
                        <a href="javascript: void(0)" (click)="redirectToPage(n?.redirect_to, n?.module_id)" class="{{  n?.redirect_to ? 't-badge-o' : 'no-button'}}"
                          >{{ n?.module }}
                          <span class="text-transform-none ml-1"
                            >#{{ n?.number }}</span
                          ></a
                        >
                      </p>
                      <p *ngIf="n.is_read">
                        {{ n?.name }} {{ n?.module_event }}
                        <a href="javascript: void(0)" (click)="redirectToPage(n?.redirect_to, n?.module_id)" class="{{  n?.redirect_to ? 't-badge-o' : 'no-button'}}"
                          >{{ n?.module }}
                          <span class="text-transform-none ml-1"
                            >#{{ n?.number }}</span
                          ></a
                        >
                      </p>

                      <!-- redirect button -->
                      <!-- <div *ngIf="n?.redirect_to"> -->
                        <!-- <a *ngIf="n?.redirect_to" href="javascript: void(0)" (click)="redirectToPage(n?.redirect_to, n?.module_id)">Go to {{ n?.redirect_to }}</a> -->
                      <!-- </div> -->

                    </div>
                  </div>
                  <div class="time-stamp">
                    {{ n?.createdAt | date : 'dd/MM/yyyy h:mm a' }}
                  </div>
                </div>
              </div>

              <div
                class="text-center mt-4"
                *ngIf="
                  notificationsLists.length >= 10 &&
                  isAll == false &&
                  (filterID == 0 || filterID == 1)
                "
              >
                <a
                  href="javascript: void(0)"
                  class="add-link-sm"
                  (click)="getAllNotifications()"
                >
                  View All Notification
                </a>
              </div>
              <div *ngIf="notificationsLists.length == 0">
                <app-no-records></app-no-records>
              </div>
            </div>
            <div class="tab-content" id="unread"></div>
            <div class="tab-content" id="request"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
